
exports.onInitialClientRender = () => {
  var Tawk_API=Tawk_API||{},
   Tawk_LoadStart=new Date();
  (function(){
  var s1=document.createElement("script"),
  s0=document.getElementsByTagName("script")[0];
  s1.async=true;
  s1.src='https://embed.tawk.to/616700c286aee40a5736633d/1fht5luqp';
  s1.charset='UTF-8';
  s1.setAttribute('crossorigin','*');
  s0.parentNode.insertBefore(s1,s0);
  })();
}